import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo.svg';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import { selectIsPeacekeeper } from '../../features/root/rootSlice';
import { RELOAD_DOCUMENT_REQUIRED, PAGES_WITHOUT_NAV } from '../../../shared/enums';

const LazyImg = loadable(() => import('../common/LazyImg'));

/**
 * @param pathname
 * @param isNavActive
 * @param setIsNavActive
 * @param topBarRef
 * @param mainRef
 * @param headerRef
 * @returns {JSX.Element}
 * @constructor
 */
const Header = ({
  pathname, isNavActive, setIsNavActive, topBarRef, mainRef, headerRef,
}) => {
  const isPeacekeeper = useSelector(selectIsPeacekeeper);
  const [ primaryNavVisible, setPrimaryNavVisible ] = useState(false);
  const [ currentHeaderOffsetTop, setCurrentHeaderOffsetTop ] = useState(0);
  const [ headerFixed, setHeaderFixed ] = useState(false);

  const setPrimaryNavVisibleHandle = e => {
    e.preventDefault();
    setPrimaryNavVisible(!primaryNavVisible);
    if (headerRef?.current.offsetWidth > 960) return;
    setIsNavActive(!isNavActive);
  };

  const navs = [
    { href: '/', text: 'Overview' },
    { href: '/performance', text: 'Performance' },
    { href: '/filters', text: 'Filters' },
    { href: '/why-mila', text: 'Why Mila?' },
    { href: '/our-story', text: 'Our Story' },
    { href: '/the-wall-of-love', text: 'Reviews' },
  ];

  const navItems = navs.map(nav => (
    RELOAD_DOCUMENT_REQUIRED.includes(pathname) ? (
      <li className='primary-nav__item'>
        <a
          href={nav.href}
          className={
            (nav.href === pathname) || (pathname.includes('/filters/') && nav.href === '/filters')
              ? 'primary-nav__item--current'
              : undefined
          }
        >
          {nav.text}
        </a>
      </li>
    ) : (
      <li className='primary-nav__item' onClick={e => setPrimaryNavVisibleHandle(e)}>
        <Link
          to={nav.href}
          className={
            (nav.href === pathname) || (pathname.includes('/filters/') && nav.href === '/filters')
              ? 'primary-nav__item--current'
              : undefined
          }
        >
          {nav.text}
        </Link>
      </li>
    )
  ));

  useEffect(() => {
    function handleResize() {
      const topBar = topBarRef?.current;
      const topBarHeight = topBar !== null ? topBar.offsetHeight : 0;
      // const headerHeight = headerRef.current.offsetHeight;
      // const topSpace = topBarHeight + headerHeight;
      const main = mainRef?.current;
      setCurrentHeaderOffsetTop(topBarHeight);
      if (main) {
        // temporarily disabled while the Marquee library in TopBar.jsx works
        // main.style.paddingTop = `${topSpace}px`;
      }

      if (headerRef.current.offsetWidth > 960) {
        setPrimaryNavVisible(true);
      } else {
        setPrimaryNavVisible(false);
      }
    }

    function handleScroll() {
      const scrollPosition = document.documentElement.scrollTop;

      const isHeaderFixed = scrollPosition > currentHeaderOffsetTop;
      setHeaderFixed(isHeaderFixed);
    }

    handleScroll();
    handleResize();

    window.addEventListener('resize', handleResize);

    window.addEventListener('resize', handleScroll);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('DOMContentLoaded', handleResize);

      window.removeEventListener('resize', handleScroll);
      window.removeEventListener('DOMContentLoaded', handleScroll);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.style.top = `${currentHeaderOffsetTop}px`;
    }
  }, [ currentHeaderOffsetTop ]);

  return (
    <header
      id='site-header'
      className={`header-refactored ${(headerFixed && !PAGES_WITHOUT_NAV.includes(pathname)) ? 'header-refactored--fixed' : ''} ${pathname.includes('/lp/why-mila') ? 'header-refactored--alt' : ''} ${PAGES_WITHOUT_NAV.includes(pathname) ? 'header-refactored--no-nav' : ''
      }`}
      ref={headerRef}
    >
      <div className='container'>
        <div className='logo desktop-logo'>
          <a href='/'>
            <span className='screen-reader-text'>Mila Homepage</span>
            <img src={logo} alt='Mila branding' />
          </a>
        </div>
        {!PAGES_WITHOUT_NAV.includes(pathname) && (
          <>
            <div className='logo mobileNav__toggle'>
              <a
                href='/'
                className='js-nav-toggle'
                onClick={e => setPrimaryNavVisibleHandle(e)}
              >
                <span className='screen-reader-text'>Mila branding</span>
                <LazyImg src={logo} alt='Mila' />
              </a>
            </div>
            <nav className='primary-nav'>
              <ul className='primary-nav__inner'>
                {React.Children.toArray(navItems)}
              </ul>
            </nav>
            <a id='order_now' href={isPeacekeeper ? '/lp/gotcha' : '/buy'} className='btn btn--small'>Order Now</a>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
